import { makeStyles } from "@material-ui/core/styles";

export const tryStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  tilesWrapper: {
    maxWidth: "1400px",
    height: "100%",
    margin: "1em auto",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    padding: "0.5em 0",
  },
  navbar: {
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5em",
    marginTop: "0.5em",
    boxShadow: "0 2px 2px -2px rgba(0,0,0,.2)",
  },
  tiles1: {
    width: "30%",
    // height: "500px",
    padding: "1em",
    overflowY: "scroll",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  jotTile: {
    width: "94%",
    margin: "1em",
    padding: "1em",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  map: {
    width: "100%",
    height: "550px",
    margin: "10px",
    overflowY: "scroll",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  tiles2: {
    width: "30%",
    display: "flex",
    padding: "1em",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  tiles3: {
    width: "20%",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  tiles4: {
    width: "30%",
    minHeight: "600px",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  tiles5: {
    minWidth: "65%",
    minHeight: "500px",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  tiles6: {
    minWidth: "28%",
    height: "500px",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  tiles7: {
    minWidth: "70%",
    borderRadius: "10px",
  },
  tiles8: {
    width: "100%",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  batteryTile: {
    width: "100%",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  tiles9: {
    width: "100%",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  newTiles9: {
    width: "100%",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  chartTile: {
    // width: "48%",
    width: "100%",
    margin: "10px",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  chart: {
    width: "100%",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  t1: {
    minWidth: "650px",
    maxHeight: "400px",
    boxShadow: "2px 2px 30px rgba(0,0,0,0.1)",
    "-webkit-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "2px 2px 30px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    margin: "1em 0",
    padding: "1em",
  },
  rootRight: {
    flex: "1",
    background: `url("../../assets/display.jpg")`,
    backgroundSize: "cover",
    backgroundPosition: "left",
  },
  float: {
    position: "fixed",
    width: "60px",
    height: "60px",
    bottom: "40px",
    right: "40px",
    backgroundColor: "#651fff",
    color: "#FFF",
    borderRadius: "50px",
    textAlign: "center",
    boxShadow: "2px 2px 3px #999",
    cursor: "pointer",
    transitionDelay: "10ms",
    "&:hover": {
      width: "62px",
      height: "62px",
    },
  },
}));
