import * as types from "../types";

const initialState = {
  loading: false,
  allData: null,
  requestDevice: null,
  error: null,
};

export default function fetchAllReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ALL_REQUEST:
      return {
        ...state,
        loading: true,
        requestDevice: action.payload.requestDevice,
      };

    case types.FETCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        allData: action.payload.allData,
      };

    case types.FETCH_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        allData: null,
        requestDevice: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
