import React from "react";
import { RealtimeStyles } from "./realtime.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getData } from "../../utils";

const Dashboard = ({
  current,
  currentLoading,
  today,
  todayLoading,
  ...props
}) => {
  const classes = RealtimeStyles();

  const rtppValue = () => {
    let value = getData(today[3].rtpp[0]);
    if (value === "NA") {
      return value;
    } else {
      return value.toFixed(2);
    }
  };

  return (
    <div>
      <h1>Realtime Data (10 minutes)</h1>
      <hr></hr>
      <div className={classes.text}>
        <p>Total Packets:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{current && getData(current.total_packets[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>Trip on Packets:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{current && getData(current.trip_on_packets[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>RTPP:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{current && getData(current.rtpp[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>Lock Percentage:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{current && getData(current.loc_per[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>Last ADC Value:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{current && getData(current.last_adc_val[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>Average VBAT:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{current && getData(current.avg_vbat[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>Average Fuel liter:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>
            {current && getData((current.avg_fuel_lit[0] / 100).toFixed(2))}
          </span>
        )}
      </div>
      <div className={classes.text}>
        <p>Average Speed:</p>
        {currentLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{current && getData(current.avg_speed[0])}</span>
        )}
      </div>
      <h1>Todays Stats</h1>
      <hr></hr>
      <div className={classes.text}>
        <p>GSM %:</p>
        {todayLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{today && rtppValue()}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>GPS %:</p>
        {todayLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{today && getData(today[3].loc_per[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>Total Packets Received:</p>
        {todayLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{today && getData(today[3].total_packets[0])}</span>
        )}
      </div>
      <div className={classes.text}>
        <p>Trip ON Packets Received:</p>
        {todayLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          <span>{today && getData(today[3].trip_on_packets[0])}</span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  current: state.realtimeReducer.realtime,
  currentLoading: state.realtimeReducer.loading,
  today: state.allReducer.allData,
  todayLoading: state.allReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
