import React, { useState, useEffect } from "react";
import ReactFusioncharts from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getChartTimeSummary } from "../../utils";
// Resolves charts dependancy
charts(FusionCharts);

function FuelPlot({ ...props }) {
  const [series, setSeries] = useState([]);
  const [min, setMin] = useState();

  useEffect(() => {
    if (props.data) {
      let awsData = [];

      let yAxisMin = props.data[0].fuel_level_dashboard / 100;

      for (let i = 0; i < props.data.length; i += 5) {
        let fuel_level = props.data[i].fuel_level_dashboard / 100;
        awsData.push({
          label: getChartTimeSummary(props.data[i].time_stamp.concat("+00:00")),
          value: fuel_level,
        });
      }
      awsData.sort(function (a, b) {
        var dateA = new Date(a.label).getTime();
        var dateB = new Date(b.label).getTime();
        return dateA < dateB ? -1 : 1; // ? 1 : -1 for descending/decreasing order
      });

      setSeries(awsData);
      setMin(yAxisMin);
    }
  }, [props.data]);

  const chartConfigs = {
    chart: {
      caption: "Fuel Plot",
      captionFont: "Arial",
      captionFontSize: "18",
      captionFontColor: "#993300",
      captionFontBold: "1",
      xAxisName: "TimeStamp (IST)",
      yAxisName: "Fuel in Litres",
      showValues: "0",
      showBorder: "0",
      showCanvasBorder: "0",
      drawAnchors: "0",
      xAxisNameFontSize: "20",
      xAxisNameFontColor: "#651fff",
      yAxisNameFontSize: "20",
      yAxisNameFontColor: "#651fff",
      yAxisMinValue: `${min}`,
      labelStep: "60",
      baseFontSize: "11",
      plottooltext:
        "<div>TimeStamp : <b>$label</b></div><hr class='demo'>Fuel in Litres : <b>$dataValue</b>",
      theme: "fusion",
    },
    data: series,
  };

  function ChartData() {
    if (series && series.length > 0) {
      return (
        <ReactFusioncharts
          type="line"
          dataFormat="JSON"
          width="100%"
          height="600"
          dataSource={chartConfigs}
        />
      );
    } else {
      return (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      );
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {props.data && props.data.length === 0 ? (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      ) : (
        ChartData()
      )}
    </div>
  );
}

export default FuelPlot;
