import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import csv from "csvtojson";
import { connect } from "react-redux";

import ADC from "../../fusionChart/fourDaysChart/adcPlot";
import ADCPlotIndex from "../../fusionChart/fourDaysChart/adcPlotIndex";
import FuelPlot from "../../fusionChart/fourDaysChart/fuelPlot";
import FuelPlotIndex from "../../fusionChart/fourDaysChart/fuelPlotIndex";
import BatteryChart from "../../fusionChart/fourDaysChart/batteryChart";

const AwsService = ({ summary, ...props }) => {
  const [newPoints, setNewPoints] = useState();

  useEffect(() => {
    if (summary) {
      AWS.config.update({
        accessKeyId: "AKIAIVEZCTLOFO7PORXA",
        secretAccessKey: "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",
        region: "us-east-2",
      });

      var s3 = new AWS.S3();

      s3.getObject(
        {
          Bucket: "amkcomputedump",
          Key: `fuel_data_dump/${props.device}.csv`,
        },

        function (error, data) {
          if (error !== null) {
            alert("Failed to retrieve an object: " + error);
          } else {
            let objectData = data.Body.toString("utf-8");
            csv()
              .fromString(objectData)
              .then((jsonObj) => {
                setNewPoints(jsonObj);
              });
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
      <ADC data={newPoints} />
      <FuelPlot data={newPoints} />
      <ADCPlotIndex data={newPoints} />
      <FuelPlotIndex data={newPoints} />
      <BatteryChart data={newPoints} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  summary: state.summaryReducer.summary,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AwsService);
