export const API_ROUTES = {
  LOGIN: "/cms/tech/api/login/",
  FUELS: "/dev/idp/fuel_test/",
  REALTIME: "/debug/get_realtime_data/",
  DEVICE_SUMMARY: "/debug/get_device_summary/",
  DEVICE_FUEL_SUMMARY: "/debug/get_fuel_summary/",
  MODEL: "/debug/get_fuel_model/",
  FUEL_CULPRIT: "/debug/get_fuel_culprits/",
  DEVICE_TICKET: "/debug/get_ticket_summary/",
  APP: "/debug/get_app_version_data/",
  INSTALLATION: "/debug/get_inst_attempts/",
  GSM_GPS: "/debug/get_gsm_info/",
  INSTALLATION_IMAGE: "/debug/get_installation_images/",
  FIREBASE: "/cms/check_presence/",
  THRESHOLD: "/debug/get_vbat_thr/",
};

export const API_BASE_URL = {
  BASE_URL: process.env.REACT_APP_DATA
    ? process.env.REACT_APP_DATA
    : "https://internal-support-portal.azurewebsites.net"
};
