import * as types from "../types";

export const fetchAllRequest = ({ requestDevice }) => ({
  type: types.FETCH_ALL_REQUEST,
  payload: {
    requestDevice,
  },
});

export const fetchAllSuccess = ({ allData }) => ({
  type: types.FETCH_ALL_SUCCESS,
  payload: {
    allData,
  },
});

export const fetchAllFailure = ({ error }) => ({
  type: types.FETCH_ALL_FAILURE,
  payload: {
    error: error.message,
  },
});
