import { takeLatest, call, put } from "redux-saga/effects";
import { fetchAllFailure, fetchAllSuccess } from "../actions/fetchAllAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchAllSaga(action) {
  try {
    yield put(clearErrors());
    let data = yield call(_api.fetchAllApi, {
      requestDevice: action.payload.requestDevice,
    });
    let newData = data.replaceAll("\\", "");
    let parsedData = newData.replaceAll('"{', "{");
    let newParsedData = parsedData.replaceAll('}"', "}");
    let allData = JSON.parse(newParsedData);
    yield put(fetchAllSuccess({ allData }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchAllFailure({ error: e }));
  }
}
export default function* fetchAllSaga() {
  yield takeLatest(types.FETCH_ALL_REQUEST, asyncFetchAllSaga);
}
