import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import moment from "moment";

const FuelAlerts = ({ alerts }) => {
  const [fuelAlertsData, setFuelAlertsData] = useState([]);

  useEffect(() => {
    let fuelAlertsdata = [];
    if (
      alerts &&
      Object.keys(alerts[1].notification_content_android).length > 0
    ) {
      let newAlert = Object.values(alerts[1].notification_content_android);

      let dateArray = [];

      for (let i = 0; i < 8; i++) {
        let date = moment().subtract(i, "d").format("YYYY-MM-DD");
        dateArray.push(date);
      }

      let alertArray = [];
      for (let value of newAlert) {
        alertArray.push({
          date: value.data.time_stamp.slice(0, 10),
          title: value.data.category,
        });
      }

      let flag = false;
      if (dateArray) {
        for (let i = 0; i < 8; i++) {
          if (alertArray) {
            for (let value of alertArray) {
              if (dateArray[i] === value.date) {
                flag = true;
                fuelAlertsdata.push({
                  date: dateArray[i],
                  title: value.title,
                });
              }
            }
          }
          if (!flag) {
            fuelAlertsdata.push({
              date: dateArray[i],
              title: "",
            });
          }
          flag = false;
        }
      }
      setFuelAlertsData(fuelAlertsdata);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  return (
    <div style={{ width: "100%", overflowY: "scroll", height: "300px" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Refuel Alert</TableCell>
              <TableCell align="center">Low Fuel Alert</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fuelAlertsData.map((row) => (
              <TableRow key={row.date}>
                <TableCell component="th" scope="row" align="center">
                  {row.date}
                </TableCell>
                <TableCell align="center">
                  {row.title === "Refuel Alert" ? (
                    <p style={{ color: "green" }}>{row.title}</p>
                  ) : (
                    <p></p>
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.title === "Low Fuel Alert" ? (
                    <p style={{ color: "red" }}>{row.title}</p>
                  ) : (
                    <p></p>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alerts: state.allReducer.allData,
  alertsLoading: state.allReducer.loading,
  summary: state.summaryReducer.summary,
  summaryLoading: state.summaryReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FuelAlerts);
