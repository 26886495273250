import React, { useState, useEffect } from "react";
import ReactFusioncharts from "react-fusioncharts";
import { connect } from "react-redux";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getChartTimeSummary } from "../../utils";
// Resolves charts dependancy
charts(FusionCharts);

function AdcPlot({ summary, summaryLoading, ...props }) {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (props.data) {
      let awsData = [];
      for (let i = 0; i < props.data.length; i += 5) {
        if (!summaryLoading) {
          if (summary.hw_batch[0] === 100) {
            let adc_value = Math.abs(
              props.data[i].fuel_adc_value - props.data[i].fuel_b_adc_value
            );
            let final_adc_value = (adc_value / 1000 / 4.3 / 3.3) * 4030;
            awsData.push({
              label: getChartTimeSummary(
                props.data[i].time_stamp.concat("+00:00")
              ),
              value: final_adc_value,
            });
          } else {
            let adc_value = Math.abs(
              props.data[i].fuel_adc_value - props.data[i].fuel_b_adc_value
            );
            awsData.push({
              label: getChartTimeSummary(
                props.data[i].time_stamp.concat("+00:00")
              ),
              value: adc_value,
            });
          }
        }
      }
      awsData.sort(function (a, b) {
        var dateA = new Date(a.label).getTime();
        var dateB = new Date(b.label).getTime();
        return dateA < dateB ? -1 : 1; // ? 1 : -1 for descending/decreasing order
      });

      console.log(awsData);
      setSeries(awsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const chartConfigs = {
    chart: {
      caption: "ADC Plot",
      xAxisName: "TimeStamp (IST)",
      yAxisName: "Magnitude",
      showValues: "0",
      showBorder: "0",
      showCanvasBorder: "0",
      drawAnchors: "0",
      xAxisNameFontSize: "20",
      xAxisNameFontColor: "#651fff",
      yAxisNameFontSize: "20",
      yAxisNameFontColor: "#651fff",
      labelStep: "60",
      baseFontSize: "11",
      plottooltext:
        "<div>TimeStamp : <b>$label</b></div><hr class='demo'>ADC Value : <b>$dataValue</b>",
      theme: "fusion",
    },
    data: series,
  };

  function ChartData() {
    if (series && series.length > 0) {
      return (
        <ReactFusioncharts
          type="line"
          dataFormat="JSON"
          width="100%"
          height="600"
          dataSource={chartConfigs}
        />
      );
    } else {
      return (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      );
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {props.data && props.data.length === 0 ? (
        <div style={{ textAlign: "center", padding: "5em" }}>
          <CircularProgress />
        </div>
      ) : (
        ChartData()
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  summary: state.summaryReducer.summary,
  summaryLoading: state.summaryReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdcPlot);
