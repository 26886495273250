import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const Battery = ({ data, dataLoading, summary, summaryLoading, ...props }) => {
  const [batteryData, setBatteryData] = useState([]);

  useEffect(() => {
    let batterydata = [];
    if (data) {
      let created_at = Object.values(data[0].created_at);
      let trip_off_avg = Object.values(data[0].trip_off_avg_vbat);
      let trip_off_packet = Object.values(data[0].trip_off_packet_count);
      let trip_on_avg = Object.values(data[0].trip_on_avg_vbat);
      let trip_on_packet = Object.values(data[0].trip_on_packet_count);

      for (let i = 0; i < created_at.length; i++) {
        batterydata.push({
          created: created_at[i],
          tripOffAvg: trip_off_avg[i],
          tripOffCount: trip_off_packet[i],
          tripOnAvg: trip_on_avg[i],
          tripOnCount: trip_on_packet[i],
        });
      }
      setBatteryData(batterydata);
    }
  }, [data]);

  let difference;

  if (!summaryLoading && summary) {
    if (
      summary.hw_batch[0] === 49 ||
      summary.hw_batch[0] === 51 ||
      summary.hw_batch[0] === 52
    ) {
      difference = 30;
    } else {
      difference = 250;
    }
  }

  return (
    <div style={{ height: "250px", overflowY: "scroll", width: "100%" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Avg_Vbat_trip_off</TableCell>
              <TableCell align="center">trip_off_pts</TableCell>
              <TableCell align="center">Avg_Vbat_trip_on</TableCell>
              <TableCell align="center">trip_on_pts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batteryData.map((row) => (
              <TableRow key={row.created}>
                {Math.abs(row.tripOnAvg - row.tripOffAvg) < difference ? (
                  <TableCell
                    style={{ background: "red", color: "white" }}
                    align="center"
                  >
                    {row.created}
                  </TableCell>
                ) : (
                  <TableCell align="center">{row.created}</TableCell>
                )}
                <TableCell align="center">{row.tripOffAvg}</TableCell>
                {Math.abs(row.tripOnAvg - row.tripOffAvg) < difference ? (
                  <TableCell
                    style={{ background: "red", color: "white" }}
                    align="center"
                  >
                    {row.tripOffCount}
                  </TableCell>
                ) : (
                  <TableCell align="center">{row.tripOffCount}</TableCell>
                )}
                <TableCell align="center">{row.tripOnAvg}</TableCell>
                {Math.abs(row.tripOnAvg - row.tripOffAvg) < difference ? (
                  <TableCell
                    style={{ background: "red", color: "white" }}
                    align="center"
                  >
                    {row.tripOnCount}
                  </TableCell>
                ) : (
                  <TableCell align="center">{row.tripOnCount}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.allReducer.allData,
  dataLoading: state.allReducer.loading,
  summary: state.summaryReducer.summary,
  summaryLoading: state.summaryReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Battery);
