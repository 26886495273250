import React from "react";
import { summaryStyles } from "./summary.styles";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  getData,
  getDate,
  getTimeSummary,
  boolValue,
  getDateDiff,
} from "../../utils";
import {
  FaMapMarkerAlt,
  FaMobileAlt,
  FaUserAlt,
  FaBroadcastTower,
  FaTractor,
} from "react-icons/fa";

const Summary = ({
  summary,
  summaryLoading,
  fuelapp,
  appLoading,
  ...props
}) => {
  const classes = summaryStyles();

  return (
    <div>
      <h1>Summary</h1>
      <hr></hr>
      <div className={classes.text}>
        <p>Device Details:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getData(summary.device_id[0])}</span>
            )}
          </>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div className={classes.text}>
          <p>
            <FaUserAlt />
          </p>
          {summaryLoading ? (
            <Skeleton animation="wave" width={100} />
          ) : (
            <>
              {!summary ? (
                <Skeleton animation="wave" width={40} />
              ) : (
                <span>{getData(summary.user_name[0])}</span>
              )}
            </>
          )}
        </div>
        <div className={classes.text}>
          <p>
            <FaMobileAlt />
          </p>
          {summaryLoading ? (
            <Skeleton animation="wave" width={100} />
          ) : (
            <>
              {!summary ? (
                <Skeleton animation="wave" width={100} />
              ) : (
                <span>{summary.phone[0]}</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className={classes.text}>
        <p>
          <FaMapMarkerAlt />
        </p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>
                {summary.location[0]}, {summary.district[0]},{summary.state[0]}
              </span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>
          <FaTractor />
        </p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{summary.tractor_name[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Model:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{summary.model[0]}</span>
            )}
          </>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div className={classes.text}>
          <p>
            <FaBroadcastTower />
          </p>
          {summaryLoading ? (
            <Skeleton animation="wave" width={50} />
          ) : (
            <>
              {!summary ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                <span>{getData(summary.operator[0])}</span>
              )}
            </>
          )}
        </div>
        <div className={classes.text}>
          <p>Sim Active:</p>
          {summaryLoading ? (
            <Skeleton animation="wave" width={70} />
          ) : (
            <>
              {!summary ? (
                <Skeleton animation="wave" width={70} />
              ) : (
                <span>{boolValue(summary.sim_active[0])}</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className={classes.text}>
        <p>Summary Label:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getData(summary.label[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>HW Batch</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{summary.hw_batch[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Installation date:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getDate(summary.installation_date[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Subscription due date:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getDateDiff(summary.subscription_due_date[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Dealer Code:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{summary.dealer_code[0]}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Area Manager:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getData(summary.Area_Manager[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Last Ping Time (IST):</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getTimeSummary(summary.last_ping_time[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Loc Time Stamp (IST):</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getTimeSummary(summary.loc_time_stamp[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Customer registered on App:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>
                {boolValue(summary.Customer_registered_on_customer_app[0])}
              </span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>App First Login Date:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getDate(summary.App_first_login_date[0])}</span>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>App Version:</p>
        {appLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!fuelapp ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <>
                {fuelapp.app_build[0] === "mahindra" ? (
                  <>
                    {1.9 <= fuelapp.app_version[0] ? (
                      <span style={{ color: "green" }}>
                        {fuelapp.app_version[0]}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {fuelapp.app_version[0]}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {fuelapp.app_build[0] === "simha" ? (
                      <>
                        {27.8 <= fuelapp.app_version[0] ? (
                          <span style={{ color: "green" }}>
                            {fuelapp.app_version[0]}
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            {fuelapp.app_version[0]}
                          </span>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className={classes.text}>
        <p>Firm Name:</p>
        {summaryLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          <>
            {!summary ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <span>{getData(summary.firm_name[0])}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  summary: state.summaryReducer.summary,
  summaryLoading: state.summaryReducer.loading,
  fuelapp: state.appReducer.app,
  appLoading: state.appReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
